import React, { useRef, useEffect } from 'react';
import './NodeComponent.css';
import { useDispatch } from 'react-redux';
import { updateNode, addLink, removeLink, changeTimeCost, changeLaborCost } from "./redux/nodesSlice";

const NodePopup = ({ node, isPopupOpen, onClose }) => {
  const popupRef = useRef(null);
  const dispatch = useDispatch();

  const handleAmountChange = (e) => {
    dispatch(changeTimeCost({ nodeId: node.id, newTimeCost: { ...node.timeCost, amount: e.target.value } }));
  };

  const handleLabourCostChange = (e) => {
    dispatch(changeLaborCost({ nodeId: node.id, newLaborCost: e.target.value }));
  };

  const handleNoteChange = (e) => {
    dispatch(updateNode({ ...node, notes: e.target.value }));
  };

  const handleLinkUrlChange = (e) => {
    node.newLink = e.target.value;
  };

  const handleLinkBlur = () => {
    if (node.newLink && node.isValidUrl(node.newLink)) {
      dispatch(addLink({ nodeId: node.id, url: node.newLink }));
    }
    node.newLink = '';
  };

  const handleRemoveLink = (index) => {
    dispatch(removeLink({ nodeId: node.id, linkIndex: index }));
  };

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isPopupOpen]);

  if (!isPopupOpen) return null;
  if (!node) return null

  const isHourlyActiveClass = node.timeCost.unit === 'hours' ? 'active' : ''
  const isDailyActiveClass  = node.timeCost.unit === 'days' ? 'active' : ''

  return (
    <div className="popup" ref={popupRef}>
      <h3>{node?.title}</h3>
      
      <div className="popup-panel">
        <h4>Edit Costs</h4>
        <div className="unit-buttons">
          <button
            className={`left ${isHourlyActiveClass}`}
            onClick={() => {
              dispatch(changeTimeCost({ nodeId: node.id, newTimeCost: { ...node.timeCost, unit: 'hours' } }));
            }}
          >
            Hours
          </button>
          <button
            className={`right ${isDailyActiveClass}`}
            onClick={() => {
              dispatch(changeTimeCost({ nodeId: node.id, newTimeCost: { ...node.timeCost, unit: 'days' } }));
            }}
          >
            Days
          </button>
        </div>
        <label>
          Labour Amount:
          <input
            type="number" min="0"
            value={node.timeCost.amount}
            onChange={handleAmountChange}
          />
        </label>
        <label>
          Labour Cost $/h:
          <input
            type="number" min="0"
            value={node.timeCost.labourCost}
            onChange={handleLabourCostChange}
          />
        </label>
      </div>

      <div className="panel-separator" />

      <div className="popup-panel">
        {/*<h5 className="section-title">Notes</h5>*/}
        <textarea
          className={"note"}
          value={node.notes || []}
          onChange={handleNoteChange}
          placeholder="Add notes here"
        />
        <div className="link-section">
          <h5 className="section-title">Links</h5>
          <input
            type="url"
            value={node.newLink || ''}
            onChange={handleLinkUrlChange}
            onBlur={handleLinkBlur}
            placeholder="Paste link here"
            onPaste={() => {
              setTimeout(() => {
                document.activeElement.blur();
              }, 0);
            }}
          />
          <ul>
            {node.links.map((link, index) => (
              <li key={index} className="link-item">
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.url}
                </a>
                <button className="remove-link" onClick={() => handleRemoveLink(index)}>X</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NodePopup;