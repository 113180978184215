import superagent from 'superagent';
import {PORTS} from "./PORTS";
import axios from "axios";
import {isDev} from "./isDev";


let SERVER = 'https://releasefaster.com'
let WS = 'wss://releasefaster.com:8080';
// let WS = 'ws://91.201.113.238:8080';

if (isDev) {
  SERVER = 'http://localhost:' + PORTS.SERVER;
  WS = 'ws://localhost:8080'
  // WS = 'wss://localhost:8080'
}

const sendPostRequest = async (url, data) => {
  return superagent.post(SERVER + url, data)
    .then(r => {
      console.log('sendPostRequest', r)
      return r.body
    })
}

const get = async (url) => {
  // const response = await axios.get(SERVER + url) //.then(response => {
  // return Promise.resolve(response.data)
  const response = await axios.get(SERVER + url) //.then(response => {
  return Promise.resolve(response.data)
}

const post = async (url, data) => {
  console.log('POST', url, data)

  const response = await axios.post(SERVER + url, data)
  return Promise.resolve(response.data)
}

const getFormattedTask = jobDescription => sendPostRequest('/reformat', {jobDescription})
const fetchProjects = async () => {
  return await get(`/api/projects`)
}

const syncWithServer = (nodes, nextId, projectId) => {
  // var spl = window.location.pathname.split('/')
  // const projectId = spl[spl.length - 1]

  const data = {
    projectId,
    nodes: nodes,
    nextId: nextId,
    jobDescription: '',
    formattedJobDescription: '',
    date: new Date()
  }

  API.post(`/api/update`, data)
    .then(r => {
    })
    .catch(err => {
      console.error('ERROR', err)
    })
}

const API = {
  SERVER,
  WS,

  getFormattedTask,
  fetchProjects,
  syncWithServer,

  get, post,
}

export default API