import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateNode,
  spawnChildNode,
  spawnSiblingNode,
  calculateTotalTimeCost,
  calculateTotalMoneyCost,

  fetchProjectById, selectNode,
} from './redux/store';
import NodeComponent from './NodeComponent';
import JobDescriptionFormatter from "./JobDescriptionFormatter";
import API from "./API";
import {isOptional, isProblem} from "./utils";
import './RightPanel.css';
import Seats from "./Seats.io";
import WebSocketComponent from "./WebsocketComponent";
import {isDev} from "./isDev";

const App = () => {
  const dispatch = useDispatch();
  var spl = window.location.pathname.split('/')
  const projectId = spl[spl.length - 1]

  useEffect(() => {
    console.log('Component will mount equivalent');
    dispatch(fetchProjectById({projectId}))
  }, []);

  const nodes = useSelector(state => state.nodes.nodes);
  const nextId = useSelector(state => state.nodes.nextId);
  const isFetched = useSelector(state => state.nodes.isFetched);

  useEffect(() => {
    const isContentLoadedInitially = nextId > 0
    if (isContentLoadedInitially && !isFetched)
      API.syncWithServer(nodes, nextId, projectId)
  }, [nodes, nextId, isFetched])

  const selectedNodeId = useSelector(state => state.menu.chosenNodeIndex);
  const selectedNode = nodes[selectedNodeId];
  const parentNode = selectedNode ? nodes[selectedNode.parentId] : null;
  const childrenNodes = selectedNode ? Object.values(nodes).filter(n => n.parentId === selectedNodeId) : [];

  const childrenNodesSorted = useSelector(state => {
    return childrenNodes
      .map(childNode => ({
        node: childNode,
        totalTimeCost: isOptional(childNode) ? -1 : calculateTotalTimeCost(state, childNode.id),
        totalMoneyCost: calculateTotalMoneyCost(state, childNode.id)
      }))
      .sort((a, b) => {
        var aPoints = a.totalTimeCost
        var bPoints = b.totalTimeCost
        // b.totalTimeCost - a.totalTimeCost

        const RISK_BONUS = 1000000;
        if (isProblem(a.node)) aPoints += RISK_BONUS
        if (isProblem(b.node)) bPoints += RISK_BONUS

        if (isOptional(a.node)) aPoints -= RISK_BONUS * 2;
        if (isOptional(b.node)) bPoints -= RISK_BONUS * 2;

        return bPoints - aPoints;
      })
  });

  const siblings = parentNode ? Object.values(nodes).filter(n => n.parentId === parentNode.id && n.id !== selectedNodeId) : [];

  const handleNodeUpdate = (updatedNode) => {
    dispatch(updateNode(updatedNode));
    console.log('Updated Node:', updatedNode);
  };

  const handleChildSpawn = (parentId, futureTitle = '') => {
    dispatch(spawnChildNode({parentId, title: futureTitle}));
    console.log('Child node spawned with parent ID:', parentId);
  };

  const handleSiblingSpawn = (siblingId) => {
    dispatch(spawnSiblingNode(siblingId));
    console.log('Sibling node spawned with sibling ID:', siblingId);
  };

  const parentBlock = parentNode && (
    <NodeComponent
      key={parentNode.id}
      node={parentNode}
      onUpdate={handleNodeUpdate}
      onChildSpawn={handleChildSpawn}
      onSiblingSpawn={handleSiblingSpawn}
    />
  );

  const currentNodeBlock = selectedNode && (
    <NodeComponent
      key={selectedNode.id}
      node={selectedNode}
      onUpdate={handleNodeUpdate}
      onChildSpawn={handleChildSpawn}
      onSiblingSpawn={handleSiblingSpawn}
    />
  );

  const childrenBlock = childrenNodesSorted.map((sortedChildNode) => (
    <NodeComponent
      key={sortedChildNode.node.id}
      node={sortedChildNode.node}
      onUpdate={handleNodeUpdate}
      onChildSpawn={handleChildSpawn}
      onSiblingSpawn={handleSiblingSpawn}
    />
  ));

  const siblingsBlock = siblings.map((siblingNode) => (
    <React.Fragment key={siblingNode.id}>
      <div style={{display: 'inline-block'}}>OR</div>
      <NodeComponent
        node={siblingNode}
        onUpdate={handleNodeUpdate}
        onChildSpawn={handleChildSpawn}
        onSiblingSpawn={handleSiblingSpawn}
      />
    </React.Fragment>
  ));

  const titleee = nodes[1]?.title || ''

  return (
    <>
      {!isDev && <WebSocketComponent projectId={projectId}/>}
      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 70%'}}>
          <div>
            <center>{parentBlock}</center>
          </div>
          <br/>
          <br/>
          <div>
            <center>{currentNodeBlock}</center>
          </div>
          <br/>
          <br/>
          <div>
            <br/>
            <br/>
            <br/>
            <center>{childrenBlock}</center>
          </div>
          {titleee.toLowerCase().includes("ticket") ? <Seats/> : ''}
        </div>
        <div className={"right-panel"} style={{flex: '0 0 30%', minHeight: '100vh'}}>
          <JobDescriptionFormatter/>
        </div>
      </div>
    </>
  );
};

export default App;