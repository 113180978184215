import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  selectNode,
  deleteSelectedNodes,
  calculateTotalTimeCost,
  calculateTotalMoneyCost,
  updateNode,
  deselectNode,
  openNodeEditingTab
} from "./redux/store";
import {isOptional} from "./utils";

const Spawner = ({node, onChildSpawn}) => {
  const [futureTitle, setFutureTitle] = useState('')
  const onSubmit = () => {
    if (futureTitle.length) {
      onChildSpawn(node.id, futureTitle)
      setFutureTitle('')
    } else {
      alert('len: ' + futureTitle.length + ' ' + futureTitle)
    }
  }

  const dispatch = useDispatch();
  const nodeRef = useRef(null);

  return <div ref={nodeRef}>
    <input placeholder={"add more..."} autoFocus={false} value={futureTitle} onChange={ev => setFutureTitle(ev.target.value)}/>
    {futureTitle && <button onClick={onSubmit}>+</button>}
  </div>
}

const HOLDING_DURATION_BEFORE_DELETE = 1750;
const HOLDING_THRESHOLD_TILL_ANIMATION = 15

const NodeComponent = ({ node, onChildSpawn, onSiblingSpawn }) => {
  const nodeId = node.id;

  const [isEditing, setIsEditing] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const chosenNodeIndex = useSelector(state => state.menu.chosenNodeIndex);
  const isSelected = chosenNodeIndex === nodeId;
  const title = node.title

  const {
    preTitle,
    nodeClass,
  } = getNodeColors(isSelected, title, node)

  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const inputRef = useRef(null);

  const handleTitleChange = (e) => {
    try {
      const updatedNode = {...node, title: e.target.value };
      dispatch(updateNode(updatedNode));
    } catch (e) {
      console.error('handleTitleChange')
    }
  };

  const deleteNode = () => {
    const parentId = node?.parentId;
    if (parentId) {
      dispatch(deleteSelectedNodes({chosenNodeIndex: nodeId, parentId}));
      dispatch(selectNode(parentId));
    }
  }

  const handleKeyDown = (e) => {
    if (!isSelected) return;

    const enterPressed = e.key === 'Enter';

    if (isEditing && enterPressed) {
      e.preventDefault()
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    if (progress < HOLDING_THRESHOLD_TILL_ANIMATION) {
      dispatch(selectNode(nodeId));
      dispatch(openNodeEditingTab());
    }
  }

  const handleClickOutside = (event) => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      dispatch(deselectNode());
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const totalNodeTimeCost = useSelector(state => calculateTotalTimeCost(state, nodeId));
  const totalNodeMoneyCost = useSelector(state => calculateTotalMoneyCost(state, nodeId));

  const isNeedsToGetAttention = !isOptional(node) || isSelected

  const fmtTime = v => v === Math.floor(v) ? v : v.toFixed(1)

  const moneyCost = <span className={"costs-money"}>{totalNodeMoneyCost.toFixed(0)} USD</span>
  const timeCost = <span className={"costs-hours"}>{fmtTime(totalNodeTimeCost)}h</span>
  const timeCostPhrase = totalNodeMoneyCost ? <> • {timeCost}</> : ''
  const costs = <div className={"costs-wrapper"}>
    <div onClick={() => setIsPopupOpen(true)}>
      {totalNodeMoneyCost ? moneyCost : ''}{timeCostPhrase}
    </div>
  </div>

  const titleComponent = isEditing ? (
    <input
      type="text"
      value={node.title}
      onChange={handleTitleChange}
      onKeyDown={handleKeyDown}
      autoFocus={true}
      ref={inputRef}
    />
  ) : (
    <div style={{ height: '100%', width: '100%' }}>
      <div className={"node-title"} onClick={() => {setIsEditing(true)}}>{node.title}</div>
    </div>
  )

  const [isHolding, setIsHolding] = useState(false);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);

  const startHold = (e) => {
    if (e.button === 2 || isEditing) {
      return;
    }

    setIsHolding(true);
    let elapsed = 0;
    intervalRef.current = setInterval(() => {
      elapsed += 100;
      setProgress((elapsed / HOLDING_DURATION_BEFORE_DELETE) * 100);
      if (elapsed >= HOLDING_DURATION_BEFORE_DELETE) {
        clearInterval(intervalRef.current);
        deleteNode();
      }
    }, 100);
  };

  const cancelHold = () => {
    setIsHolding(false);
    clearInterval(intervalRef.current);

    setTimeout(() => {
      setProgress(0);
    }, 150)
  };

  const handleTouchStart = (e) => {
    startHold(e.touches[0]);
  };

  const handleTouchEnd = () => {
    cancelHold();
  };

  return (
    <>
    <div
      className={`node ${nodeClass}`}
      onClick={handleClick}
      onMouseDown={startHold}
      onMouseUp={cancelHold}
      onMouseLeave={cancelHold}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{borderWidth: '2px', opacity: isNeedsToGetAttention ? 1: 0.35}}
      ref={nodeRef}
    >
      <div className="node-type">{preTitle}</div>
      <div className="node-costs">{costs}</div>
      <div className="node-title-component"><center>{titleComponent}</center></div>
      <div className="node-child-spawn">{isSelected && <Spawner onChildSpawn={onChildSpawn} node={node} />}</div>
      <RadialProgressHold progress={progress} isHolding={isHolding} />
    </div>
    </>
  );
};

const RadialProgressHold = ({ progress, isHolding }) => {
  return (
    <div
      className="hold-to-remove-container"
      style={{opacity: progress > HOLDING_THRESHOLD_TILL_ANIMATION ? 1 : 0}}
    >
      <div
        className="progress-radial"
        style={{
          background: `conic-gradient(
            rgb(255 0 0 / 70%) ${progress * 3.6}deg,
            rgba(0, 0, 0, 0.1) ${progress * 3.6}deg
          )`,
          transition: isHolding ? "none" : "background 0.2s",
        }}
      ></div>
      <div
        className="progress-content"
      >
        🗑
      </div>
    </div>
  );
};

const getNodeColors = (isSelected, title, node) => {
  let borderColor = '#463f3f'
  var nodeClass = ''

  const isRisk = title.endsWith('?')
  const isSolution = title.startsWith('?')
  const isEndNode = !node.children.length

  if (isSolution) {
    nodeClass += ' solution '
    borderColor = 'blue'
  }

  if (isRisk) {
    nodeClass += ' risk '
    borderColor = 'red'
  }

  if (isSelected) {
    nodeClass += ' active '
    borderColor = 'gold'
  }

  if (isEndNode) {
    nodeClass += ' end '
  }

  const getPreTitle = () => {
    var t = '';

    if (isOptional(node))
      t += 'Optional '

    if (isRisk)
      t += 'Problem '

    if (isSolution)
      t += 'Solution? '

    return t
  }

  let preTitle = getPreTitle();
  const bgColor = borderColor

  return {
    nodeClass,
    preTitle,
    bgColor,
    isSolution,
    isRisk,
  }
}

export default NodeComponent;
