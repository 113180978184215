import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chosenNodeIndex: 1,
  // activeTab: 'original',
  activeTab: 'nodePopup',
  isPopupOpen: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState: initialState,
  reducers: {
    selectNode: (state, action) => {
      const chosenNodeIndex = action.payload;
      // if (state.nodes[chosenNodeIndex]) {
        state.chosenNodeIndex = chosenNodeIndex;
      // }
    },
    deselectNode: (state, action) => {
      // state.chosenNodeIndex = 1;
    },
    toggleTab: (state, action) => {
      state.activeTab = action.payload;
    },
    togglePopup: (state) => {
      state.isPopupOpen = !state.isPopupOpen;
    },
    openNodeEditingTab: state => {
      state.activeTab = 'nodePopup'
    }
  },
});

export const { selectNode, toggleTab, togglePopup, deselectNode, openNodeEditingTab } = menuSlice.actions;
export default menuSlice.reducer;