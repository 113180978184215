import { configureStore } from '@reduxjs/toolkit';
import nodesSlice from "./nodesSlice";
import menuSlice from "./menuSlice";
import websocketSlice from "./websocketSlice";

const store = configureStore({
  reducer: {
    nodes: nodesSlice,
    menu: menuSlice,
    websocket: websocketSlice,
  },
});

export * from './menuSlice'
export * from './nodesSlice'
export * from './websocketSlice'

export default store;