import { SeatsioSeatingChart } from '@seatsio/seatsio-react';

// const workspaceKey = 'e18796e5-d094-42ee-9595-19d40f1570e5'
const workspaceKey = 'f2019179-dae7-4ab6-aa58-e698940d6253'
const eventKey = 'd3284565-b531-4b21-956e-e1b4bc0fb134'

const Seats = () => {
  return <div style={{'height': '500px'}}>
    <div>Seats.io integration example</div>
    <SeatsioSeatingChart
      workspaceKey={workspaceKey}
      event={eventKey}
      region="eu"
    />
  </div>
}

export default Seats