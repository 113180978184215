import { createSlice } from '@reduxjs/toolkit';

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    connected: false,
    events: [], // To store received events
  },
  reducers: {
    setConnected: (state, action) => {
      state.connected = action.payload;
    },
    addEvent: (state, action) => {
      state.events.push(action.payload);
    },
  },
});

export const { setConnected, addEvent } = websocketSlice.actions;
export default websocketSlice.reducer;
