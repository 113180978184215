import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderComponent = (Component) => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <Component />
      </Provider>
    </React.StrictMode>
  );
};

const renderApp = () => {
  const path = window.location.pathname;

  if (path === '/') {
    import('./MainPage').then((MainPage) => {
      renderComponent(MainPage.default);
    });
  } else if (path.startsWith('/projects/')) {
    renderComponent(App);
  } else if (path === '/list') {
    import('./ProjectsPage').then((Projects) => {
      renderComponent(Projects.default);
    });
  }
};

renderApp();

window.addEventListener('popstate', renderApp);

reportWebVitals();